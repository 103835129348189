import React, { useEffect, useState } from "react"
import { TextAreaWithCounter } from "../Inputs/TextArea"
import StyledDropzone from "../Inputs/StyledDropzone"
import { useForm, ValidationError } from "@formspree/react"
import axios from "axios"
import config from "../../../config"

const DesignChecklist = ({ setSucceeded }: { setSucceeded: Function }) => {
  const [hasBrandStyleGuide, setHasBrandStyleGuide] = useState(true)
  const [hasFontFiles, setHasFontFiles] = useState(true)
  const [hasOtherAssets, setHasOtherAssets] = useState(false)
  const [useStockPhotos, setUseStockPhotos] = useState(false)
  const [notifyTeam, setNotifyTeam] = useState(false)
  const [submissionError, setSubmissionError] = useState("")
  const [uploadedFiles, setUploadedFiles] = useState<
    { name: string; file: File }[]
  >([])
  const [missingFiles, setMissingFiles] = useState<string[]>([])
  const [state] = useForm("xdoqwavl")
  const [teamMembers, setTeamMembers] = useState<
    { name: string; email: string }[]
  >([{ name: "", email: "" }])

  useEffect(() => {
    if (state.succeeded) {
      setSucceeded(true)
    }
  }, [state.succeeded, setSucceeded])

  const submitForm = async (form: React.FormEvent<HTMLFormElement>) => {
    form.preventDefault()
    console.log("submitting form")
    setSubmissionError("")
    setMissingFiles([])
    const formData = new FormData()
    const formElements = form.currentTarget.elements
    const teamMembersJson = []
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i] as HTMLInputElement
      if (element.name) {
        if (element.name.startsWith("teamFullName")) {
          const teamIndex = element.name.split("teamFullName")[1]

          const teamEmail = formElements.namedItem(
            `teamEmail${teamIndex}`
          ) as HTMLInputElement
          teamMembersJson.push({ name: element.value, email: teamEmail.value })
        } else if (element.name === "teamEmail") {
          // ignore team email inputs
        } else if (element.type === "file") {
          // ignore file inputs
        } else if (element.type === "radio") {
          if (element.checked) {
            if (element.checked) {
              formData.append(
                element.name,
                element.value === "yes" ? "true" : "false"
              )
            }
          }
        } else if (element.type === "checkbox") {
          if (element.checked) {
            formData.append(element.name, element.value)
          }
        } else if (element.value) {
          formData.append(element.name, element.value)
        }
      }
    }

    formData.append("teamMembers", JSON.stringify(teamMembersJson))
    if (uploadedFiles.length > 10) {
      setSubmissionError("You can only upload a maximum of 10 files")
      return
    }

    const requiredFiles = []
    if (hasBrandStyleGuide) {
      requiredFiles.push("brandStyleGuide")
    }
    if (hasFontFiles) {
      requiredFiles.push("fontFiles")
    }
    if (hasOtherAssets) {
      requiredFiles.push("otherFiles")
    }
    requiredFiles.push("logoFiles")
    const missingFiles = requiredFiles.filter(
      name => !uploadedFiles.some(file => file.name === name)
    )
    if (missingFiles.length > 0) {
      setSubmissionError(
        `Please upload the following files: ${missingFiles.join(", ")}`
      )
      setMissingFiles(missingFiles)
      return
    }

    uploadedFiles.forEach(({ name, file }) => {
      formData.append(name, file)
    })

    try {
      const baseURL = config.NEW_API_HOST
      if (!baseURL) return
      const response = await axios.post(`${baseURL}/form/design`, formData)
      if (response.status === 200) {
        setSucceeded(true)
      }
      console.log(response.data)
    } catch (error) {
      console.error(error)
      const errorText =
        error.response && error.response.data && error.response.data.error
      if (errorText === "File too large") {
        setSubmissionError(
          "One of the files you uploaded is too large. Please make sure all files are under 10MB."
        )
      } else {
        setSubmissionError(
          "There was an error submitting the form. Please try again later."
        )
      }
    }
  }

  return (
    <div className="policies-table">
      <form
        className="client-form rounded-13px shadow-md border border-gray-3 px-12 py-10 mx-auto md:ml-175px max-w-lg sm:translate-y-0 bg-white"
        onSubmit={submitForm}
        encType={"multipart/form-data"}
      >
        {/*  Company name */}
        <h4 className="text-24px mb-4 font-normal">*Organization name:</h4>
        <div className="flex">
          <input
            name="companyName"
            placeholder="Your company name"
            required
            type="text"
            className="border border-gray-10 rounded-md shadow-sm bg-white placeholder-[#3E3E41] w-full text-15px py-10px px-15px mb-8 mr-3"
          />
          <ValidationError
            prefix="Company name"
            field="companyName"
            errors={state.errors}
          />
          <input
            name="companyWebsite"
            placeholder="Your company website"
            required
            type="text"
            className="border border-gray-10 rounded-md shadow-sm bg-white placeholder-[#3E3E41] w-full text-15px py-10px px-15px mb-8 ml-3"
          />
          <ValidationError
            prefix="Company website"
            field="companyWebsite"
            errors={state.errors}
          />
        </div>
        {" "}

        {/* TODO: add these everywhere */}
        {/* Your Details */}
        <h4 className="text-24px mb-4 font-normal">*Your information:</h4>
        <div className="flex">
          <input
            name="fullName"
            placeholder="Full name"
            required
            type="text"
            className="border border-gray-10 rounded-md shadow-sm bg-white placeholder-[#3E3E41] w-full text-15px py-10px px-15px mb-8 mr-3"
          />
          <ValidationError
            prefix="Full name"
            field="fullName"
            errors={state.errors}
          />
          <input
            name="email"
            placeholder="Email address"
            required
            type="email"
            className="border border-gray-10 rounded-md shadow-sm bg-white placeholder-[#3E3E41] w-full text-15px py-10px px-15px mb-8 ml-3"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        {/*  Company website */}

        {/* Company description */}
        <TextAreaWithCounter
          title={"Describe your business in a few words:"}
          name={"companyDescription"}
          placeholder={
            "Example: We are an analytics software platform; we are a local bank."
          }
          formSpreeState={state}
        />
        {/* Theme */}
        <TextAreaWithCounter
          title={
            "*What overall style or theme should we aim to achieve with the handbook design?"
          }
          name={"theme"}
          required
          placeholder={
            "Example: a clean, modern look; highly professional and corporate feeling."
          }
          formSpreeState={state}
        />
        {/*  Brand Style Guide */}
        <h4 className="text-24px mb-4 font-normal">
          *Select / drop your style guide file(s) here
        </h4>
        <div className="flex mb-8">
          <div className="flex items-center mr-8">
            <input
              id="inline-radio-styleguide"
              type="radio"
              value="yes"
              name="hasBrandStyleGuide"
              defaultChecked={hasBrandStyleGuide}
              onChange={() => setHasBrandStyleGuide(true)}
              className="w-5 h-5 placeholder-[#3E3E41] bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-radio-styleguide"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                hasBrandStyleGuide ? "font-bold" : ""
              }`}
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="inline-2-radio-styleguide"
              type="radio"
              value="no"
              name="hasBrandStyleGuide"
              defaultChecked={!hasBrandStyleGuide}
              onChange={() => setHasBrandStyleGuide(false)}
              className="w-5 h-5 placeholder-[#3E3E41] bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-2-radio-styleguide"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                !hasBrandStyleGuide ? "font-bold" : ""
              }`}
            >
              No
            </label>
          </div>
        </div>
        <StyledDropzone
          className={`mb-8 ${!hasBrandStyleGuide ? "hidden" : ""}`}
          text="*Drop your brand guidelines here"
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          isFileRequiredAndMissing={missingFiles.includes("brandStyleGuide")}
          inputName="brandStyleGuide"
        />
        <div className={`mb-8 ${hasBrandStyleGuide ? "hidden" : ""}`}>
          <TextAreaWithCounter
            title={"Provide us with specific colours we should incorporate:"}
            name="colours"
            formSpreeState={state}
            placeholder="Hex code, colour name, RGB, etc."
          />
        </div>
        {/* Font upload */}
        <h4 className="text-24px mb-4 font-normal">
          Are there certain brand font files we must use?
        </h4>
        <h5 className="text-14px mb-4 italic font-normal leading-17px">
          {(hasFontFiles)
            ? "*For licensing purposes, please provide us with the .otf or .ttf font files. Please note your organization is responsible for the ownership and/or permissible use of any fonts provided to AirMason for use in designing your documents on our platform"
            : "AirMason will select free font files on your behalf."
          }
        </h5>
        <div className="flex mb-8">
          <div className="flex items-center mr-8">
            <input
              id="inline-radio-fontfiles"
              type="radio"
              value="yes"
              required
              name="hasFontFiles"
              defaultChecked={hasFontFiles}
              onChange={() => setHasFontFiles(true)}
              className="w-5 h-5 placeholder-[#3E3E41] bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-radio-styleguide"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                hasFontFiles ? "font-bold" : ""
              }`}
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="inline-2-radio-fontfiles"
              type="radio"
              value="no"
              name="hasFontFiles"
              defaultChecked={!hasFontFiles}
              onChange={() => setHasFontFiles(false)}
              className="w-5 h-5 placeholder-[#3E3E41] bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-2-radio-styleguide"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                !hasFontFiles ? "font-bold" : ""
              }`}
            >
              No
            </label>
          </div>
        </div>
        <StyledDropzone
          className={`mb-8 ${!hasFontFiles ? "hidden" : ""}`}
          text="*Select / drop your font file(s) here"
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          isFileRequiredAndMissing={missingFiles.includes("fontFiles")}
          inputName="fontFiles"
        />
        {/* Company logo files */}
        <h4 className="text-24px mb-4 font-normal">
          *Provide your company logo files:
        </h4>
        <h5 className="text-14px mb-4 italic font-normal leading-17px">
          High-res PNG / SVG of both colour and white versions.
        </h5>
        <StyledDropzone
          className="mb-8"
          text="*Select / drop your logo file(s) here"
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          isFileRequiredAndMissing={missingFiles.includes("logoFiles")}
          inputName="logoFiles"
        />
        {/*  Other images */}
        <h4 className="text-24px mb-4 font-normal">
          *Do you have any images, videos, or other assets you would like to
          include into your handbook?
        </h4>
        <h5 className="text-14px mb-4 italic font-normal leading-17px">
          Example: team pics, corporate photography, icons, illustrations, GIFs,
          etc.
        </h5>
        <div className="flex mb-8">
          <div className="flex items-center mr-8">
            <input
              id="inline-radio-assets"
              type="radio"
              value="yes"
              name="hasOtherAssets"
              defaultChecked={hasOtherAssets}
              onChange={() => setHasOtherAssets(true)}
              className="w-5 h-5 placeholder-[#3E3E41] bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-radio-assets"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                hasOtherAssets ? "font-bold" : ""
              }`}
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="inline-2-radio-assets"
              type="radio"
              value="no"
              name="hasOtherAssets"
              defaultChecked={!hasOtherAssets}
              onChange={() => setHasOtherAssets(false)}
              className="w-5 h-5 placeholder-[#3E3E41] bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-2-radio-assets"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                !hasOtherAssets ? "font-bold" : ""
              }`}
            >
              No
            </label>
          </div>
        </div>
        <StyledDropzone
          className={`mb-8 ${!hasOtherAssets ? "hidden" : ""}`}
          text="Please attach any/all images and other assets here"
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          isFileRequiredAndMissing={missingFiles.includes("otherFiles")}
          inputName="otherFiles"
        />
        <div  className={`${!hasOtherAssets ? "hidden" : ""}`}>
        <TextAreaWithCounter
          title={
            "If you would like to have your videos embedded, please provide the direct URL’s here:"
          }
          name={"videoURLs"}
          placeholder={"Example: www.youtube.com/watch?v=zBSfZFkqCrc"}
          formSpreeState={state}

        />
        <h5 className="text-14px mb-8 italic font-normal leading-17px">
          Please note the videos must be hosted on a hosting platform such as YouTube, Vimeo, etc.
        </h5>
        </div>
        {/*  Use royalty free images? */}
        <div className={`${!hasOtherAssets ? "hidden" : ""}`}>
          <h4 className="text-24px mb-4 font-normal">
            *Would you like us to source and incorporate stock images (if
            needed)?
          </h4>
          <h5 className="text-14px mb-8 italic font-normal leading-17px">
            A note that if too few company photos/images are supplied initially,
            we may use stock placeholders until we receive files to replace
            with. This can be addressed during the revision process.
          </h5>
          <div className="flex mb-4">
            <div className="flex items-center mr-8">
              <input
                id="inline-radio-stock"
                type="radio"
                value="yes"
                name="useStockPhotos"
                defaultChecked={useStockPhotos}
                onChange={() => setUseStockPhotos(true)}
                className="w-5 h-5 placeholder-[#3E3E41] bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
              />
              <label
                htmlFor="inline-radio-stock"
                className={`ml-2 text-14px font-medium text-gray-900 ${
                  useStockPhotos ? "font-bold" : ""
                }`}
              >
                Yes
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="inline-2-radio-stock"
                type="radio"
                value="no"
                name="useStockPhotos"
                defaultChecked={!useStockPhotos}
                onChange={() => setUseStockPhotos(false)}
                className="w-5 h-5 placeholder-[#3E3E41] bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
              />
              <label
                htmlFor="inline-2-radio-stock"
                className={`ml-2 text-14px font-medium text-gray-900 ${
                  !useStockPhotos ? "font-bold" : ""
                }`}
              >
                No
              </label>
            </div>
          </div>
        </div>
        <div
          className={`mb-8 ${
            useStockPhotos || !hasOtherAssets ? "" : "hidden"
          }`}
        >
          {/* Photo Theme */}
          <TextAreaWithCounter
            title={
              "*In lieu of your own images being supplied, what kind of stock photo theme(s) should we be searching? Anything you want us to avoid?"
            }
            name={"photoTheme"}
            required={useStockPhotos || !hasOtherAssets}
            placeholder={
              "Example: corporate feeling, hip and trendy, no people shown, abstract, etc."
            }
            formSpreeState={state}
          />
          <h4 className="text-24px mb-4 font-normal">
            If you have any examples of stock photos please drop them here:
          </h4>
          <StyledDropzone
            className="mb-8"
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            text="Select / drop your stock photo file(s) here"
            isFileRequiredAndMissing={missingFiles.includes("stockPhotos")}
            inputName="stockPhotos"
          />
        </div>
        {/* Final comments */}
        <TextAreaWithCounter
          title={"Things to avoid:"}
          name={"thingsToAvoid"}
          placeholder={
            "Example: please don't use stock photos; no white backgrounds; we don't like the colour blue! If nothing, please write N/A."
          }
          formSpreeState={state}
        />
        <TextAreaWithCounter
          title={"Any other important notes for our design team:"}
          name={"finalComments"}
          placeholder=""
          formSpreeState={state}
        />
        {/* Notify Team? */}
        <h4 className="text-24px mb-4 font-normal">
          Are there any other contacts that should be CC’d in the email to receive the first draft?
        </h4>
        <div className="flex mb-8">
          <div className="flex items-center mr-8">
            <input
              id="inline-radio-notifyTeam"
              type="radio"
              value="yes"
              name="notifyTeam"
              checked={notifyTeam}
              onClick={() => setNotifyTeam(true)}
              className="w-5 h-5 placeholder-[#3E3E41] bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-radio-notifyTeam"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                notifyTeam ? "font-bold" : ""
              }`}
            >
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="inline-2-radio-notifyTeam"
              type="radio"
              value="no"
              name="notifyTeam"
              checked={!notifyTeam}
              onClick={() => setNotifyTeam(false)}
              className="w-5 h-5 placeholder-[#3E3E41] bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor="inline-2-radio-notifyTeam"
              className={`ml-2 text-14px font-medium text-gray-900 ${
                !notifyTeam ? "font-bold" : ""
              }`}
            >
              No
            </label>
          </div>
        </div>
        {/* Their Details */}
        <div className={`${notifyTeam ? "" : "hidden"}`}>
          <h4 className="text-24px mb-4 font-normal">Enter their details</h4>
          {teamMembers.map((teamMember, index) => (
            <div className="flex items-baseline" key={index}>
              <input
                name={`teamFullName${index}`}
                placeholder="Full name"
                type="text"
                required={notifyTeam}
                id={index.toString()}
                value={teamMember.name}
                onChange={e => {
                  const newTeamMembers = [...teamMembers]
                  newTeamMembers[index].name = e.target.value
                  setTeamMembers(newTeamMembers)
                }}
                className="border border-gray-10 rounded-md shadow-sm bg-white placeholder-[#3E3E41] w-full text-15px py-10px px-15px mb-3 mr-3"
              />
              <input
                name={`teamEmail${index}`}
                placeholder="Email address"
                type="email"
                required={notifyTeam}
                id={index.toString()}
                value={teamMember.email}
                onChange={e => {
                  const newTeamMembers = [...teamMembers]
                  newTeamMembers[index].email = e.target.value
                  setTeamMembers(newTeamMembers)
                }}
                className="border border-gray-10 rounded-md shadow-sm bg-white placeholder-[#3E3E41] w-full text-15px py-10px px-15px mb-3 ml-3"
              />
              <button
                type="button"
                className={`font-extrabold ml-2 ${
                  teamMembers.length > 1 ? "" : "invisible"
                }`}
                onClick={() => {
                  const newTeamMembers = [...teamMembers]
                  newTeamMembers.splice(index, 1)
                  setTeamMembers(newTeamMembers)
                }}
              >
                X
              </button>
            </div>
          ))}
          <button
            type="button"
            className="mb-8"
            onClick={() => {
              setTeamMembers([...teamMembers, { name: "", email: "" }])
            }}
          >
            Add another person
          </button>
        </div>
        {/* Submit */}
        <div className="flex items-center mb-6">
          <button
            type="submit"
            disabled={state.submitting}
            style={{ flex: 1 }}
            className="py-2 px-4 bg-blue-1 rounded-full text-white text-15px"
          >
            {state.submitting ? "Submitting..." : "Submit form"}
          </button>
          <span className="text-red ml-3" style={{ flex: 3 }}>
            {submissionError
              ? submissionError
              : state.errors.length
                ? state.errors[0].message
                : ""}
          </span>
        </div>
        <span className="text-14px placeholder-[#3E3E41]">
          This form allows you to submit a maximum of 10 files. If you have
          more, please upload zip files or send them to us via email.
        </span>
      </form>
    </div>
  )
}

export default DesignChecklist
